import React from "react";
import "./HomePage.css";

const HomePage = () => {
  return (
    //     <div className="homepage">

    //       <div className="hero-section">
    //         <div className="hero-text">
    //           <h1>LOOKING FOR A HOME TUTOR?</h1>
    //           <h1>LOOKING FOR AN ONLINE TUTOR?</h1>
    //           <p>Studyhelpline is here to help.</p>

    // <a href="https://wa.me/9250192507" target="_blank"> <button className="contact-button">Contact Us</button></a>
    //         </div>
    //       </div>
    //       <div className="achievements">
    //       <div className="achievements-container">
    //         <div className="achievement-item">
    //           <i className="achievement-icon fa fa-users"></i>
    //           <p>We are a team of</p>
    //           <h3>49,673</h3>
    //           <p>Verified & Tested Tutors</p>
    //         </div>
    //         <div className="achievement-item">
    //           <i className="achievement-icon fa fa-university"></i>
    //           <p>Our Students are from</p>
    //           <h3>2,589</h3>
    //           <p>Schools of India </p>
    //         </div>
    //         <div className="achievement-item">
    //         <i class="fa-regular fa-face-smile"></i>
    //           <p>Inquiries Posted by</p>
    //           <h3>10,352</h3>
    //           <p>Students</p>
    //         </div>
    //       </div>
    //       <footer className="footer">
    //         <div className="footer-logo">
    //         <h1>Studyhelpline</h1>
    //           <p>To provide the most transparent, honest, and efficient services...</p>
    //         </div>
    //         <div className="footer-columns">
    //           <div className="footer-column">
    //             <h4>Area of work</h4>
    //             <ul>
    //               <li>Tutors in Delhi</li>
    //               <li>Tutors in Mumbai</li>

    //             </ul>
    //           </div>
    //           <div className="footer-column">
    //             <h4>Information</h4>
    //             <ul>
    //               <li>About Us</li>

    //               <li>Contact Us</li>

    //             </ul>
    //           </div>
    //         </div>
    //       </footer>
    //     </div>
    //     </div>

    <div className="homepage">
      <nav>
        <div class="menu">
          <i class="ri-menu-line"></i>
        </div>
        <div class="company" style={{fontSize:"25px" , fontWeight:"700"}} >Studyhelpline</div>
        <div class="links">
          <a href="#">Find a tutor</a>
          <a href="#">How it works</a>
          <a href="#">Prices</a>
          <a href="#">
            Resources <span></span>
          </a>
          <a href="#">For schools</a>
          <a href="/register">Become a tutor</a>
        </div>
        <div class="mobilelinks" >
        <a href="/register">Become a tutor</a>

        </div>

        <div class="contact">
          {/* <a href="tel:+911234567890">+911234567890</a> */}
          <a href="https://wa.me/9250192507" target="_blank">Contact us </a>
       
          <button class="signbtn"><a href="/termandcondition" >TermsAndConditions</a></button>
        </div>
      </nav>

      <main>
        <div class="mainleft">
          <h1>Find a Tutor and Let Your Child Shine
         </h1>
          <p>
            We can't stop you worrying about your child. But our expert tutors
            can help their grades and confidence soar - and help you worry a
            little less.
          </p>
          <div class="search">
            <div class="searchbox">
              <input placeholder="Search a subject" type="text" />
              <i class="ri-search-line"></i>
            </div>
            <button   ><a href="https://wa.me/9250192507" target="_blank"  > Get Started</a></button>
          </div>
        </div>

        <div class="mainright"></div>
      </main>

      <div class="strip">
        <p>
          Excellent{" "}
          <span>
            <i class="ri-star-fill"></i>
          </span>
          <span>
            <i class="ri-star-fill"></i>
          </span>
          <span>
            <i class="ri-star-fill"></i>
          </span>
          <span>
            <i class="ri-star-fill"></i>
          </span>
        </p>
        <h2>426,535+ 5-star reviews</h2>
        <h2>1500 schools trust us</h2>
        <h2>30+ subjects available</h2>
        <h2>255,203+ students</h2>
      </div>

      <div class="pick">
        <h3>Pick a subject to get started</h3>
        <div class="flexbtns">
          <button>Maths</button>
          <button>English</button>
          <button>Physics</button>
          <button>Biology</button>
          <button>Science</button>
          <button>Spanish</button>
          <button>French</button>
          <button>German</button>
          <button>History</button>
          <button>Music</button>
          <button>Phsycology</button>
          <button>Politics</button>
          <button class="allsub">All subjects</button>
        </div>
        <h3>Start learning in 3 steps</h3>
        <div class="cards">
          <div class="card">
            <div class="imgwrapper">
              <img
                src="https://www.mytutor.co.uk/_next/image?url=%2Fimages%2Fshare-your-goals.png&w=384&q=80"
                alt=""
              />
            </div>
            <h4>Share your goals</h4>
            <p>
              Let us know what type of tutoring you need and when, so we can
              find you the right matches for your needs.
            </p>
          </div>

          <div class="card">
            <div class="imgwrapper">
              <img
                src="https://www.mytutor.co.uk/_next/image?url=%2Fimages%2Ffind-the-perfect-fit.png&w=384&q=80"
                alt=""
              />
            </div>
            <h4>Find the perfect fit</h4>
            <p>
              Browse our tutors, get in touch, and book as many free meetings as
              you need to find the tutor they click with most.
            </p>
          </div>

          <div class="card">
            <div class="imgwrapper">
              <img
                src="https://www.mytutor.co.uk/_next/image?url=%2Fimages%2Flet-the-learning-begin.png&w=384&q=80"
                alt=""
              />
            </div>
            <h4>Let the learning begin</h4>
            <p>
              Once you’ve found the right fit, it’s time to book your lessons
              and start learning with MyTutor.
            </p>
          </div>
        </div>
        <div class="handpicked">
          <div class="handpickedleft">
            <h4>1.	Tell Us What You Need</h4>
            <p>
            o	Let us know your tutoring requirements, and we’ll match you with the perfect tutor. Browse through profiles of certified professionals, and submit your request. Our Education Consultant will contact you with tutor profiles and fees tailored to your budget
            </p>
          </div>

          <div class="handpickedright">
            <img
              src="https://www.mytutor.co.uk/landing/brand-image-for-feature-1.svg"
              alt=""
            />
          </div>
        </div>
        <div class="handpicked trustedby">
          <div class="handpickedleft">
            <h4>2.	Get Matched with Your Ideal Tutor</h4>
            <p>
            o	Based on your needs, we’ll find the ideal tutor. We’ll share their qualifications and experience, so you can make an informed decision. Talk to your tutor before the first class to discuss learning methods, class timings, and more.
            </p>
          </div>

          <div class="handpickedright">
            <img
              src="https://www.mytutor.co.uk/landing/brand-image-for-feature-2.svg"
              alt=""
            />
          </div>
        </div>
        <div class="handpicked helpfrom">
          <div class="handpickedleft">
            <h4>3.	Begin Your Learning Journey</h4>
            <p>
            o	Once you’ve finalized a tutor, it’s time to start your lessons. We’ll set up your schedule at a time that suits you. No extra fees – you only pay for the sessions you take.
            </p>
          </div>

          <div class="handpickedright">
            <img
              src="https://www.mytutor.co.uk/landing/brand-image-for-feature-3.svg"
              alt=""
            />
          </div>
        </div>
        <div class="handpicked ourinter">
          <div class="handpickedleft">
            <h4>•	Higher Grades, Top Rankings</h4>
            <p>
            o	Every year, our students consistently top their classes and exams, including CBSE, ICSE, and IB boards. Our tutors use adaptive methods to provide a solid support system that enhances learning and builds confidence.
            </p>
          </div>

          <div class="handpickedright">
            <img
              src="https://www.mytutor.co.uk/landing/brand-image-for-feature-4.svg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="strip2">
        <h2>As featured in</h2>
        <img src="https://cdn.mytutor.co.uk/images/sundaytimes_48.svg" alt="" />
        <img src="https://cdn.mytutor.co.uk/images/guardian_48.svg" alt="" />
        <img
          src="https://www.mytutor.co.uk/_next/image?url=https%3A%2F%2Fcdn.mytutor.co.uk%2Fimages%2Fuploads%2Fbbc_image_resized.gif&w=256&q=75"
          alt=""
        />
        <img src="https://cdn.mytutor.co.uk/images/sky_48.svg" alt="" />
      </div>

      <div class="faqcontainer">
        <img src="https://www.mytutor.co.uk/landing/brand-shape6.svg" />
        <img src="https://www.mytutor.co.uk/landing/brand-shape5.svg" alt="" />
        <div class="faq">
          <h2>Read our FAQs</h2>
          <button class="accordion acc1">What makes a good tutor ?</button>
          <div class="panel panel1">
            <p>
              Subject knowledge is a must, but being a good tutor is really
              about explaining difficult concepts in a way that's clear,
              engaging and personalised for each student. You can have three
              degrees in Physics, but that doesn't mean you'll be good at
              explaining it at GCSE level! Tutors who can empathise with their
              students and help them build confidence help teens unleash their
              potential, and we see amazing results in just a few lessons. Our
              tutors are from top UK unis, and because they're just a few years
              older, they can explain things in a way that teens find relatable.
              We interview all of our tutors, and only the friendliest and most
              knowledgeable make it on to our platform. We're very picky about
              it - just 1 in 8 applicants make the cut.
            </p>
          </div>

          <button class="accordion acc2">Which tutor is right for you ?</button>
          <div class="panel panel2">
            <p>
              Before you look for a tutor, it's helpful to have a really clear
              idea of exactly where your child needs help - whether with a
              specific English Literature text, one area of Maths or their exam
              technique - and filter your choices accordingly. If you're not
              sure where they need to focus, having a chat with them or their
              teacher can help you work out the best place to start. In a free
              meeting, you can then ask the tutor any questions you like and see
              how well they get on with your child before deciding to book.
            </p>
          </div>

          <button class="accordion acc3">
            Why is online tutoring important ?
          </button>
          <div class="panel panel3">
            <p>
              Online tutoring gives kids the chance to learn at their own pace
              and in a way that matches their learning style. Teens are often
              too shy to put their hand up in class - especially if they're
              struggling. The reassurance of one-to-one tutoring means they can
              ask all the questions they want, and go over topics as much as
              they need until they get it.
            </p>
          </div>

          <button class="accordion acc4">
            What are the benefits of online tutoring ?
          </button>
          <div class="panel panel4">
            <p>
              One-to-one tutoring lets kids unleash their potential. Worried
              about learning gaps? We'll fill them in. No tutors in your area?
              We've got you covered. No academic confidence? No problem.
              Whatever your child needs help with, their tutor will guide them
              through tricky topics and boost their self-belief. With the
              personalised one-to-one support from their tutor, your child can
              get the grades they deserve.
            </p>
          </div>

          <button class="accordion acc5">How much does a tutor cost ?</button>
          <div class="panel panel5">
            <p>
              Our tutors set their own prices based on their experience and
              qualifications, starting from £22/hour at GCSE level. Most of our
              tutors charge between £22 and £30 an hour. You can see all the
              tutors who match your budget with the handy price filter on our
              Find a tutor page.
            </p>
          </div>

          <button class="accordion acc6">How to find a tutor ?</button>
          <div class="panel panel6">
            <p>
              Finding a tutor is easy. Whatever you need, you can search and
              filter by subject, level, price, experience and more.
              <ol>
                <li>
                  View our tutors, and narrow your search by subject, academic
                  level or price. Or if you'd like expert help, book a call with
                  our free tutor matching service.
                </li>
                <li>
                  Compare their profiles to learn about their background,
                  experience and availability. Don't forget to take a peek at
                  their reviews from parents and students!
                </li>
                <li>
                  Message a tutor (or two, or three) and book your free meeting
                  at a time that suits you.
                </li>
                Book a lesson and pay as you go. There are no hidden costs and
                you can skip or cancel any time.
              </ol>
            </p>
          </div>

          <button class="accordion acc7">How do online lessons work ?</button>
          <div class="panel panel7">
            <p>
              We have our own online lesson space with video chat, messaging and
              an interactive whiteboard - this makes it easy for students and
              tutors to talk to each other, discuss tricky concepts and do
              practice questions together. With the live video chat, they can
              have a natural back-and-forth conversation - just like on
              FaceTime, Whatsapp and other apps teens use all the time.
            </p>
          </div>
        </div>
      </div>

      <div class="strip3">
        <a href="#">Maths</a>
        <a href="#">Biology</a>
        <a href="#">Chemistry</a>
        <a href="#">Physics</a>
        <a href="#">Science</a>
        <a href="#">English</a>
        <a href="#">German</a>
        <a href="#">Spanish</a>
        <a href="#">French</a>
        <a href="#">Economics</a>
        <a href="#">Physycology</a>
        <a href="#">History</a>
      </div>

      <div class="rescontainer">
        <div class="resources">
          <div class="resleft">
            <h2>Free study resources</h2>
          </div>

          <div class="resright">
            <p>
              Over a million students use our free resources to help them with
              their homework.
            </p>
            <button>Explore resources</button>
          </div>
        </div>

        
      </div>

      <div class="book">
        <img
          class="bookimg1"
          src="https://www.mytutor.co.uk/landing/brand-shape3.svg"
          alt=""
        />
        <img
          class="bookimg2"
          src="https://www.mytutor.co.uk/landing/brand-shape4.svg"
          alt=""
        />
        <h2>
          Book a free meeting with a tutor today and find out how they can help
          your child
        </h2>
        <button>Find a tutor</button>
      </div>

      <footer>
        <div class="upperfooter">
          <div class="col">
            <h3>Company Information</h3>
            <a href="#">Careers</a>
            <a href="#">Blog</a>
            <a href="#">Subject answers</a>
            <a href="#">Become a tutor</a>
            <a href="#">Schools</a>
            <a href="#">Safeguarding policy</a>
            <a href="#">FAQ'S</a>
            <a href="#">Using the Online Lesson Space</a>
            <a href="/termandcondition">TermsAndConditions</a>
            <a href="#">Sitemap</a>
          </div>

          <div class="col">
            <h3>Popular Requests</h3>
            <a href="#">Maths tutor</a>
            <a href="#">Chemistry tutor</a>
            <a href="#">Physics tutor</a>
            <a href="#">Biology tutor</a>
            <a href="#">English tutor</a>
            <a href="#">GCSE tutor</a>
            <a href="#">A level tutor</a>
            <a href="#">IB tutor</a>
            <a href="#">Physics & Maths tutor</a>
            <a href="#">Chemistry & Maths tutor</a>
            <a href="#">GCSE Maths tutor</a>
          </div>

          <div class="col">
            <h3>We are here to help</h3>
            <div class="contactus">
              <i class="ri-contacts-book-3-line"></i>
              <a href="">Contact us</a>
            </div>
            <div class="whatsapp">
              <i class="ri-whatsapp-line"></i>
              <a href="#">Message us on Whatsapp</a>
            </div>
            <div class="phone">
              <i class="ri-phone-fill"></i>
              <a href="tel:1234567890">1234567890</a>
            </div>
            <div class="social">
              <i class="ri-facebook-circle-fill"></i>
              <i class="ri-instagram-fill"></i>
              <i class="ri-linkedin-box-fill"></i>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
