// src/components/TermsAndConditions.js
import React from 'react';
import './TermsAndConditions.css'; 

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>
      <section className="terms-content">
        <p><strong>For Tutors:</strong></p>
        <ul>
          <li>Registration of Rs.1500 is mandatory.</li>
          <li>The tutor must submit all documents like photo ID proof, address proof, two photographs, and qualification proof or mail them to <a href="mailto:delhi@studyhelpline.in">delhi@studyhelpline.in</a> OR <a href="mailto:mumbai@studyhelpline.in">mumbai@studyhelpline.in</a>.</li>
          <li>Any change can be made on the website and plan without any further notification by the website.</li>
          <li>We are only a service provider portal for home tuitions. We are not responsible for any inconvenience or mishap by the parents and tutors.</li>
          <li>The tutor must accept full responsibility for the price quotes for their services on the website. We don't guarantee fluency in providing classes to tutors.</li>
          <li>The commission once collected will not be refundable to tutors.</li>
          <li>The registration fee will not be refundable if you get any classes in a month.</li>
          <li>The information of inquiries on the website will not be provided to offline registered tutors.</li>
          <li>The validity of online registration is one year. In case of a registration amount refund, only 60% of the registration amount will be refunded. 40% will be deducted as administration charges only if no trial is provided within 45 days of registration.</li>
        </ul>
        
        <p><strong>Parents:</strong></p>
        <ul>
          <li>Parents are required to take ID proof from the tutor. Studyhelpline.in does not take any responsibility for the tutors.</li>
          <li>If any issue arises regarding the “Service,” please call 9582844550.</li>
        </ul>
        
        <p><strong>Payment Terms:</strong></p>
        <ul>
          <li>If the payment is on a monthly basis, 50% payment must be made in advance by cash to our executive.</li>
          <li>If the payment is on an hourly basis, payment must be made weekly by cash to our executive.</li>
          <li>If you make payments to tutors without a bill, we will not be responsible for the particular payment.</li>
          <li>50% commission of the first month’s fee will be charged in Delhi/Mumbai.</li>
          <li>25% commission will be charged for package-based/short-term courses in Delhi/Mumbai.</li>
          <li>10% commission will be charged for yearly packages in Mumbai.</li>
          <li>Payment will be collected by the executives or you can pay online. No tutor is allowed to take fees from parents directly.</li>
        </ul>
        
        <p><strong>Performance and Safety:</strong></p>
        <ul>
          <li>A maximum of two chances will be given to the tutor to prove themselves.</li>
          <li>Future assignments will solely depend on the tutor's performance.</li>
          <li>Lady tutors must ensure their safety while taking classes at students' homes.</li>
        </ul>
        
        <p>All disputes arising out of transactions between a user and Studyhelpline.in will be subject to the jurisdiction of courts in Delhi.</p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
